import React from 'react';
import './BlogDetails.css';

import { BlogInfo, BlogSidebar, CommentForm, Comments, PageTitle } from '../../../components';

import { useLocation } from "react-router-dom";

  
const BlogDetails = () => {

  const location = useLocation();
  const blog = location.state;  

  return (   
    <>
      <PageTitle title={'blog details'} page={'blog details'} />
      <section className='blog-details'>
        <div className='blog-container'>
          <BlogInfo blog={blog} />
           
          <Comments blog={blog} />
          <CommentForm />
        </div>
        <BlogSidebar /> 
      </section>

    </>

  )
}

export default BlogDetails;