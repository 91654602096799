import React from 'react';
import './Services.css';

import { Heading } from '../../../components';
import { serviceContent } from '../../../constants';

import { Link } from 'react-router-dom'; 



const Services = () => {
  return (
    <section className='services' id='services'>

      <Heading title={'our services'} span={'what we offer'} />

      <div className='box-container'>    
        {
          serviceContent.map((service) => {
            return (
              <div className='service-item' key={service.id}>
                <div className='image'>
                  <img src={service.image} alt='' />
                </div>
                <div className='content'>
                  <Link to={{
                    pathname: '/Services/' + service.title,
                  }}     
                  location = {{service}}
                  state= {service}
                  className='main-heading'>{service.title}
                  </Link>
                  <p>{service.content}</p>
                </div>
              </div>
            )
            })
        }
      </div>
 
    </section>
  )
}

export default Services