import { images } from '../constants';


export const categories = [ 
    {
        id: 1,
        title: 'Solar Energy',
        quantity: '20',
    },
    {
        id: 2,
        title: 'Solar Panels',
        quantity: '15',
    }, 
    {
        id: 3, 
        title: 'Wind Turbines',
        quantity: '13',
    },
    {
        id: 4,
        title: 'Water energy',
        quantity: '10',
    },
    {
        id: 5,
        title: 'Hybrid energy',
        quantity: '8',
    },
];
 
export const popular = [
    {
        id: 1,
        image: images.popular1Gallery1, 
        name: 'wind turbine',
        price: '70.00',
        disprice: '50.00',
        availability: 'in stock',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        reviews: '50+',
        category: [
            'solar energy',
            'solar panels'
        ], 
        tags: [
            'solar',
            'energy',
            'panel'
        ],
        gallery: {
            image1: images.popular1Gallery1,
            image2: images.popular1Gallery2,
            image3: images.popular1Gallery3,
            image4: images.popular1Gallery4,
        }
    },
    {
        id: 2,
        image: images.popular2Gallery1,
        name: 'solar light',
        price: '70.00',
        disprice: '50.00',
        availability: 'in stock',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        reviews: '30+',
        category: [
            'solar energy',
            'solar panels'
        ], 
        tags: [
            'solar',
            'energy',
            'panel'
        ],
        gallery: {
            image1: images.popular2Gallery1,
            image2: images.popular2Gallery2,
            image3: images.popular2Gallery3,
            image4: images.popular2Gallery4,
        }
    },
    {
        id: 3,
        image: images.popular3Gallery1, 
        name: 'solar charger',
        price: '70.00',
        disprice: '50.00',
        availability: 'in stock',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        reviews: '30+',
        category: [
            'solar energy',
            'solar panels'
        ], 
        tags: [
            'solar',
            'energy',
            'panel'
        ],
        gallery: {
            image1: images.popular3Gallery1,
            image2: images.popular3Gallery2,
            image3: images.popular3Gallery3,
            image4: images.popular3Gallery4,
        }
    },
];

