{/*Global*/} 
import logo from '../assets/Logo/Logo.png';
import pageTitle from '../assets/PageTitle/PageTitle.png';


{/*Home*/}
import home1 from '../assets/Home/Home-1.png';
import home2 from '../assets/Home/Home-2.png';
import home3 from '../assets/Home/Home-3.png'; 

import about from '../assets/About/About-Image.png';

import team1 from '../assets/Team/Team-1.png';
import team2 from '../assets/Team/Team-2.png';
import team3 from '../assets/Team/Team-3.png';
import team4 from '../assets/Team/Team-4.png';

import testimonial1 from '../assets/Testimonials/pic-1.png';
import testimonial2 from '../assets/Testimonials/pic-2.png';
import testimonial3 from '../assets/Testimonials/pic-3.png';


{/*About*/}
import FAQs from '../assets/FAQs/FAQs.png';


{/*Pages*/}
import project1 from '../assets/Projects/Project-1.png';
import project2 from '../assets/Projects/Project-2.png';
import project3 from '../assets/Projects/Project-3.png';
import project4 from '../assets/Projects/Project-4.png';
import project5 from '../assets/Projects/Project-5.png';
import project6 from '../assets/Projects/Project-6.png';
import project7 from '../assets/Projects/Project-7.png';
import project8 from '../assets/Projects/Project-8.png';
import project9 from '../assets/Projects/Project-9.png';

import profile from '../assets/Profile/Profile-Pic.png';


{/*Services*/}
import service1 from '../assets/Services/1/Main.png';
import service11 from '../assets/Services/1/Gallery-1.png';
import service12 from '../assets/Services/1/Gallery-2.png';

import service2 from '../assets/Services/2/Main.png';
import service21 from '../assets/Services/2/Gallery-1.png';
import service22 from '../assets/Services/2/Gallery-2.png';

import service3 from '../assets/Services/3/Main.png';
import service31 from '../assets/Services/3/Gallery-1.png';
import service32 from '../assets/Services/3/Gallery-2.png';

import service4 from '../assets/Services/4/Main.png';
import service41 from '../assets/Services/4/Gallery-1.png';
import service42 from '../assets/Services/4/Gallery-2.png';

import service5 from '../assets/Services/5/Main.png';
import service51 from '../assets/Services/5/Gallery-1.png';
import service52 from '../assets/Services/5/Gallery-2.png';

import service6 from '../assets/Services/6/Main.png';
import service61 from '../assets/Services/6/Gallery-1.png';
import service62 from '../assets/Services/6/Gallery-2.png';


{/*Blogs*/}
import blog1 from '../assets/Blog/Blogs/1/Blog-1.png';
import blog1gallery1 from '../assets/Blog/Blogs/1/Gallery/1.png';
import blog1gallery2 from '../assets/Blog/Blogs/1/Gallery/2.png';
import blog1comment1 from '../assets/Blog/Blogs/1/Comments/pic-1.png';
import blog1comment2 from '../assets/Blog/Blogs/1/Comments/pic-2.png';
import blog1comment3 from '../assets/Blog/Blogs/1/Comments/pic-3.png';

import blog2 from '../assets/Blog/Blogs/2/Blog-2.png';
import blog2gallery1 from '../assets/Blog/Blogs/2/Gallery/1.png';
import blog2gallery2 from '../assets/Blog/Blogs/2/Gallery/2.png';
import blog2comment1 from '../assets/Blog/Blogs/2/Comments/pic-1.png';
import blog2comment2 from '../assets/Blog/Blogs/2/Comments/pic-2.png';
import blog2comment3 from '../assets/Blog/Blogs/2/Comments/pic-3.png';

import blog3 from '../assets/Blog/Blogs/3/Blog-3.png';
import blog3gallery1 from '../assets/Blog/Blogs/3/Gallery/1.png';
import blog3gallery2 from '../assets/Blog/Blogs/3/Gallery/2.png';
import blog3comment1 from '../assets/Blog/Blogs/3/Comments/pic-1.png';
import blog3comment2 from '../assets/Blog/Blogs/3/Comments/pic-2.png';
import blog3comment3 from '../assets/Blog/Blogs/3/Comments/pic-3.png';

import blog4 from '../assets/Blog/Blogs/4/Blog-4.png';
import blog4gallery1 from '../assets/Blog/Blogs/4/Gallery/1.png';
import blog4gallery2 from '../assets/Blog/Blogs/4/Gallery/2.png';
import blog4comment1 from '../assets/Blog/Blogs/4/Comments/pic-1.png';
import blog4comment2 from '../assets/Blog/Blogs/4/Comments/pic-2.png';
import blog4comment3 from '../assets/Blog/Blogs/4/Comments/pic-3.png';

import blog5 from '../assets/Blog/Blogs/5/Blog-5.png';
import blog5gallery1 from '../assets/Blog/Blogs/5/Gallery/1.png';
import blog5gallery2 from '../assets/Blog/Blogs/5/Gallery/2.png';
import blog5comment1 from '../assets/Blog/Blogs/5/Comments/pic-1.png';
import blog5comment2 from '../assets/Blog/Blogs/5/Comments/pic-2.png';
import blog5comment3 from '../assets/Blog/Blogs/5/Comments/pic-3.png';

import blog6 from '../assets/Blog/Blogs/6/Blog-6.png';
import blog6gallery1 from '../assets/Blog/Blogs/6/Gallery/1.png';
import blog6gallery2 from '../assets/Blog/Blogs/6/Gallery/2.png';
import blog6comment1 from '../assets/Blog/Blogs/6/Comments/pic-1.png';
import blog6comment2 from '../assets/Blog/Blogs/6/Comments/pic-2.png';
import blog6comment3 from '../assets/Blog/Blogs/6/Comments/pic-3.png';

import post1 from '../assets/Blog/Recent-Posts/1/Blog-1.png';
import post1gallery1 from '../assets/Blog/Recent-Posts/1/Gallery/1.png';
import post1gallery2 from '../assets/Blog/Recent-Posts/1/Gallery/2.png';
import post1comment1 from '../assets/Blog/Recent-Posts/1/Comments/pic-1.png';
import post1comment2 from '../assets/Blog/Recent-Posts/1/Comments/pic-2.png';
import post1comment3 from '../assets/Blog/Recent-Posts/1/Comments/pic-3.png';

import post2 from '../assets/Blog/Recent-Posts/2/Blog-2.png';
import post2gallery1 from '../assets/Blog/Recent-Posts/2/Gallery/1.png';
import post2gallery2 from '../assets/Blog/Recent-Posts/2/Gallery/2.png';
import post2comment1 from '../assets/Blog/Recent-Posts/2/Comments/pic-1.png';
import post2comment2 from '../assets/Blog/Recent-Posts/2/Comments/pic-2.png';
import post2comment3 from '../assets/Blog/Recent-Posts/2/Comments/pic-3.png';

import post3 from '../assets/Blog/Recent-Posts/3/Blog-3.png';
import post3gallery1 from '../assets/Blog/Recent-Posts/3/Gallery/1.png';
import post3gallery2 from '../assets/Blog/Recent-Posts/3/Gallery/2.png';
import post3comment1 from '../assets/Blog/Recent-Posts/3/Comments/pic-1.png';
import post3comment2 from '../assets/Blog/Recent-Posts/3/Comments/pic-2.png';
import post3comment3 from '../assets/Blog/Recent-Posts/3/Comments/pic-3.png';


{/*Shop*/} 
import popular1Gallery1 from '../assets/Shop/Popular/1/Product-Gallery/1.png';
import popular1Gallery2 from '../assets/Shop/Popular/1/Product-Gallery/2.png';
import popular1Gallery3 from '../assets/Shop/Popular/1/Product-Gallery/3.png';
import popular1Gallery4 from '../assets/Shop/Popular/1/Product-Gallery/4.png';

import popular2Gallery1 from '../assets/Shop/Popular/2/Product-Gallery/1.png';
import popular2Gallery2 from '../assets/Shop/Popular/2/Product-Gallery/2.png';
import popular2Gallery3 from '../assets/Shop/Popular/2/Product-Gallery/3.png';
import popular2Gallery4 from '../assets/Shop/Popular/2/Product-Gallery/4.png';

import popular3Gallery1 from '../assets/Shop/Popular/3/Product-Gallery/1.png';
import popular3Gallery2 from '../assets/Shop/Popular/3/Product-Gallery/2.png';
import popular3Gallery3 from '../assets/Shop/Popular/3/Product-Gallery/3.png';
import popular3Gallery4 from '../assets/Shop/Popular/3/Product-Gallery/4.png';

import product1Gallery1 from '../assets/Shop/Products/1/Product-Gallery/1.png';
import product1Gallery2 from '../assets/Shop/Products/1/Product-Gallery/2.png';
import product1Gallery3 from '../assets/Shop/Products/1/Product-Gallery/3.png';
import product1Gallery4 from '../assets/Shop/Products/1/Product-Gallery/4.png';

import product2Gallery1 from '../assets/Shop/Products/2/Product-Gallery/1.png';
import product2Gallery2 from '../assets/Shop/Products/2/Product-Gallery/2.png';
import product2Gallery3 from '../assets/Shop/Products/2/Product-Gallery/3.png';
import product2Gallery4 from '../assets/Shop/Products/2/Product-Gallery/4.png';

import product3Gallery1 from '../assets/Shop/Products/3/Product-Gallery/1.png';
import product3Gallery2 from '../assets/Shop/Products/3/Product-Gallery/2.png';
import product3Gallery3 from '../assets/Shop/Products/3/Product-Gallery/3.png';
import product3Gallery4 from '../assets/Shop/Products/3/Product-Gallery/4.png';

import product4Gallery1 from '../assets/Shop/Products/4/Product-Gallery/1.png';
import product4Gallery2 from '../assets/Shop/Products/4/Product-Gallery/2.png';
import product4Gallery3 from '../assets/Shop/Products/4/Product-Gallery/3.png';
import product4Gallery4 from '../assets/Shop/Products/4/Product-Gallery/4.png';

import product5Gallery1 from '../assets/Shop/Products/5/Product-Gallery/1.png';
import product5Gallery2 from '../assets/Shop/Products/5/Product-Gallery/2.png';
import product5Gallery3 from '../assets/Shop/Products/5/Product-Gallery/3.png';
import product5Gallery4 from '../assets/Shop/Products/5/Product-Gallery/4.png';

import product6Gallery1 from '../assets/Shop/Products/6/Product-Gallery/1.png';
import product6Gallery2 from '../assets/Shop/Products/6/Product-Gallery/2.png';
import product6Gallery3 from '../assets/Shop/Products/6/Product-Gallery/3.png';
import product6Gallery4 from '../assets/Shop/Products/6/Product-Gallery/4.png';

import product7Gallery1 from '../assets/Shop/Products/7/Product-Gallery/1.png';
import product7Gallery2 from '../assets/Shop/Products/7/Product-Gallery/2.png';
import product7Gallery3 from '../assets/Shop/Products/7/Product-Gallery/3.png';
import product7Gallery4 from '../assets/Shop/Products/7/Product-Gallery/4.png';

import product8Gallery1 from '../assets/Shop/Products/8/Product-Gallery/1.png';
import product8Gallery2 from '../assets/Shop/Products/8/Product-Gallery/2.png';
import product8Gallery3 from '../assets/Shop/Products/8/Product-Gallery/3.png';
import product8Gallery4 from '../assets/Shop/Products/8/Product-Gallery/4.png';

import product9Gallery1 from '../assets/Shop/Products/9/Product-Gallery/1.png';
import product9Gallery2 from '../assets/Shop/Products/9/Product-Gallery/2.png';
import product9Gallery3 from '../assets/Shop/Products/9/Product-Gallery/3.png';
import product9Gallery4 from '../assets/Shop/Products/9/Product-Gallery/4.png';

import wishlist1 from '../assets/Shop/Wishlist/1.png';
import wishlist2 from '../assets/Shop/Wishlist/2.png';
import wishlist3 from '../assets/Shop/Wishlist/3.png';
import wishlist4 from '../assets/Shop/Wishlist/4.png';
import wishlist5 from '../assets/Shop/Wishlist/5.png';

import cart1 from '../assets/Shop/Cart/1.png';
import cart2 from '../assets/Shop/Cart/2.png';
import cart3 from '../assets/Shop/Cart/3.png';
import cart4 from '../assets/Shop/Cart/4.png';
import cart5 from '../assets/Shop/Cart/5.png';

import orderlist1 from '../assets/Shop/OrderList/1.png';
import orderlist2 from '../assets/Shop/OrderList/2.png';
import orderlist3 from '../assets/Shop/OrderList/3.png';
import orderlist4 from '../assets/Shop/OrderList/4.png';
import orderlist5 from '../assets/Shop/OrderList/5.png';



const Images = {

    /*Global*/
    logo,
    pageTitle,


    /*Home*/
    home1,
    home2,
    home3,

    about,

    team1,
    team2,
    team3,
    team4,

    testimonial1,
    testimonial2,
    testimonial3,

    /*About*/
    FAQs,


    /*Pages*/
    project1,
    project2,
    project3,
    project4,
    project5,
    project6,
    project7,
    project8,
    project9,

    profile,


    /*Services*/
    service1,
    service11,
    service12,

    service2,
    service21,
    service22,

    service3,
    service31,
    service32,

    service4,
    service41,
    service42,

    service5,
    service51,
    service52,

    service6,
    service61,
    service62,

    /*Blogs*/
    blog1,
    blog1gallery1,
    blog1gallery2,
    blog1comment1,
    blog1comment2,
    blog1comment3,

    blog2,
    blog2gallery1,
    blog2gallery2,
    blog2comment1,
    blog2comment2,
    blog2comment3,

    blog3,
    blog3gallery1,
    blog3gallery2,
    blog3comment1,
    blog3comment2,
    blog3comment3,

    blog4,
    blog4gallery1,
    blog4gallery2,
    blog4comment1,
    blog4comment2,
    blog4comment3,

    blog5,
    blog5gallery1,
    blog5gallery2,
    blog5comment1,
    blog5comment2,
    blog5comment3,

    blog6,
    blog6gallery1,
    blog6gallery2,
    blog6comment1,
    blog6comment2,
    blog6comment3,

    post1,
    post1gallery1,
    post1gallery2,
    post1comment1,
    post1comment2,
    post1comment3,

    post2,
    post2gallery1,
    post2gallery2,
    post2comment1,
    post2comment2,
    post2comment3,

    post3,
    post3gallery1,
    post3gallery2,
    post3comment1,
    post3comment2,
    post3comment3,

    /*Shop*/
    popular1Gallery1,
    popular1Gallery2,
    popular1Gallery3,
    popular1Gallery4,

    popular2Gallery1,
    popular2Gallery2,
    popular2Gallery3,
    popular2Gallery4,

    popular3Gallery1,
    popular3Gallery2,
    popular3Gallery3,
    popular3Gallery4,

    product1Gallery1,
    product1Gallery2,
    product1Gallery3,
    product1Gallery4,

    product2Gallery1,
    product2Gallery2,
    product2Gallery3,
    product2Gallery4,

    product3Gallery1,
    product3Gallery2,
    product3Gallery3,
    product3Gallery4,

    product4Gallery1,
    product4Gallery2,
    product4Gallery3,
    product4Gallery4,

    product5Gallery1,
    product5Gallery2,
    product5Gallery3,
    product5Gallery4,

    product6Gallery1,
    product6Gallery2,
    product6Gallery3,
    product6Gallery4,

    product7Gallery1,
    product7Gallery2,
    product7Gallery3,
    product7Gallery4,

    product8Gallery1,
    product8Gallery2,
    product8Gallery3,
    product8Gallery4,

    product9Gallery1,
    product9Gallery2,
    product9Gallery3,
    product9Gallery4,

    wishlist1,
    wishlist2,
    wishlist3,
    wishlist4,
    wishlist5,

    cart1,
    cart2,
    cart3,
    cart4,
    cart5,

    orderlist1,
    orderlist2,
    orderlist3,
    orderlist4,
    orderlist5,

};

export default Images;