import React from 'react';
import './ServiceDetails.css';

import { PageTitle } from '../../../components';
import { serviceContent } from '../../../constants';

import { TiTick } from 'react-icons/ti';
import { MdDoubleArrow } from 'react-icons/md';
 
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'; 

 

function ServiceDetails() {

    const location = useLocation();
    const service = location.state; 

    const paragraphs = service.paragraph; 

    return (
        <>
            <PageTitle title={'service details'} page={'service details'} />
            <section className='service-details'>

                <div className='service-sidebar'>
                    {
                        serviceContent.map((service) => {
                            return(
                                <Link to={{
                                    pathname: '/Services/' + service.title,
                                    }}     
                                    location = {{service}}
                                    state= {service}>
                                    <h3><MdDoubleArrow className='icon' />{service.title}</h3>
                                </Link>
                            )
                        })
                    }
                </div>
                
                <div className='service-info'>
                    <div className='image'>
                        <img src={service.image} alt='Blog' />
                    </div>
                    <div className='content'>
                        <h3 class="main-heading">{service.title}</h3>

                        <p>{paragraphs.para1}</p>  
                        <p>{paragraphs.para2}</p> 

                        <div className='service-gallery'>
                            {
                                (service.gallery).map((item) => {
                                    return <img src={item.image} alt='' />
                                })
                            }
                        </div> 

                        <h3 class="sub-heading">{service.title} features:</h3>

                        <ul className='service-features'>
                            {
                                (service.features).map((item) => {
                                    return <li><TiTick className='icon' />{item.title}</li>
                                })
                            }
                        </ul>
                    
                    </div>

                </div>
            </section>
        </>
    )
}

export default ServiceDetails;
 