import React from 'react';
import './Project.css';
 
import { FiLink } from 'react-icons/fi';
import { BsArrowsAngleExpand } from 'react-icons/bs';

  
 
const Project = ({id, image, title, category, link, index, popup}) => {
  
  return (
    <div className='project-item' key={id}>
      <img src={image} alt='project image' />
      <div className='content'>
        <h4>{title}</h4>
        <p>{category}</p>
        <div className='btn-container'>
          <a href={link}><FiLink /></a> 
          <a onClick={() => {popup(index)}}><BsArrowsAngleExpand /></a> 
        </div>
      </div>
    </div> 
  )
}

export default Project;