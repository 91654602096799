import React from 'react';
import './Address.css';
  
  
 
const Address = ({id, title, name, country, city, province, ZIP, address}) => {
  return ( 
    <div className='address' key={id}> 
      <div className='title'><h3>{title}</h3></div>
      <div className='info'>
        <p><span>{name}</span></p> 
        <p><span>country:</span> {country}</p>
        <p><span>city:</span> {city}</p>
        <p><span>province:</span> {province}</p>
        <p><span>ZIP:</span> {ZIP}</p>
        <p><span>address:</span> {address}</p>
      </div>
    </div>
  )
}

export default Address;