import React from 'react';
import './Footer.css';

import { images } from '../../constants';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaEnvelope, FaAngleDoubleRight } from 'react-icons/fa';
import { AiTwotonePhone } from 'react-icons/ai'; 
import { BsMapFill } from 'react-icons/bs';
import Logo from '../Logo/Logo';
import { Link } from 'react-router-dom';

 
  
const Footer = () => {  
  
  return (
    <footer className='footer'>

      <div className='box-container'>

        <div className='footer-item'> 
          <Logo />
          <p>Build a solar panel to save energy costs. With Solar Panels the future is looking bright. Solar energy, the smartest way to power your house.</p>
          <div class="social">
            <a href="/#"><FaFacebookF className='icon' /></a>
            <a href="/#"><FaTwitter className='icon' /></a>
            <a href="/#"><FaInstagram className='icon' /></a>
            <a href="/#"><FaLinkedin className='icon' /></a>
          </div>
        </div>

        <div className='footer-item'>
          <h2>quick links</h2>
          <div className='info links'>
            <p><FaAngleDoubleRight className='icon' /><Link to='/'>home</Link></p> 
            <p><FaAngleDoubleRight className='icon' /><Link to='/about-us'>about</Link></p>
            <p><FaAngleDoubleRight className='icon' /><Link to='/projects'>projects</Link></p>
            <p><FaAngleDoubleRight className='icon' /><Link to='/services'>services</Link></p>
            <p><FaAngleDoubleRight className='icon' /><Link to='/blog-grid'>blog</Link></p>
            <p><FaAngleDoubleRight className='icon' /><Link to='/shop-grid'>shop</Link></p>
            <p><FaAngleDoubleRight className='icon' /><Link to='/contact-us'>contact</Link></p>
          </div>
        </div> 

        <div className='footer-item'>
          <h2>contact info</h2>
          <div className='info'>
            <p><AiTwotonePhone className='icon' /><span>+111-222-333</span></p>
            <p><AiTwotonePhone className='icon' /><span>+123-456-789</span></p>
            <p><FaEnvelope className='icon' /><span class="gmail">abc@gmail.com</span></p>
            <p><FaEnvelope className='icon' /><span class="gmail">xyz@gmail.com</span></p>
            <p><BsMapFill className='icon' /><span>karachi, pakistan</span></p>
          </div>
        </div>

      </div>

      <div className='content'>
        <p>designed by <span>AS_Designs</span> | all rights reserved</p>
      </div>
      
    </footer>
  )
}

export default Footer;