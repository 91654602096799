import React from 'react';
import './Team.css'; 

import { Heading } from '../../../components';
import { teamContent } from '../../../constants';

import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';

   
  
const Team = () => {
  return (
    <section className='team' id='team'>

        <Heading title={'our team'} span={'our expert agents'} />

        <div className='box-container'> 
        { 
            teamContent.map((item) => { 
            return (
                <div className='team-item' key={item.id}>
                    <div className='image'>
                        <img src={item.Image} alt='Team Pic' />
                    </div>
                    <div className='content'>
                        <h3>{item.name}</h3> 
                        <p>{item.title}</p> 
                    </div>
                    <div class="icon-container">
                            <a className='icon' href='#'><FaFacebookF /></a>
                            <a className='icon' href='#'><FaTwitter /></a>
                            <a className='icon' href='#'><FaInstagram /></a>
                            <a className='icon' href='#'><FaYoutube /></a> 
                        </div> 
                </div>
            )
            })
        }
        </div>

    </section>
  )
}

export default Team;