import React from 'react';
import './PageTitle.css';

import { MdDoubleArrow } from 'react-icons/md';
import { Link } from 'react-router-dom';



const PageTitle = ({title, page}) => {
  return ( 
    <div className='page-title'> 

      <div className='title'>
        <h2>{title}</h2>
      </div> 
 
      <div className='link'>
        <Link to='/'>home</Link>
        <MdDoubleArrow />
        <span className='page'>{page}</span>
      </div>

    </div>
  )
}

export default PageTitle;