const orderlist = [
    { 
        id: 1,
        orderID: '#12345',
        date: 'mar 25th, 2021',
        total: '$500.00',
        status: 'completed',
    },
    {
        id: 2,
        orderID: '#23456', 
        date: 'july 21st, 2021',
        total: '$750.00',
        status: 'completed',
    },
    {
        id: 3,
        orderID: '#34567',
        date: 'sep 26th, 2021',
        total: '$1100.00',
        status: 'cancelled',
    },
    {
        id: 4,
        orderID: '#45678',
        date: 'feb 11th, 2022',
        total: '$1350.00',
        status: 'processing',
    },
    {
        id: 5,
        orderID: '#56789',
        date: 'may 23rd, 2022',
        total: '$1500.00',
        status: 'processing',
    },
];

export default orderlist;