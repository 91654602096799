import React from 'react';
import './BlogSidebar.css';

import { SidebarHeading, Blog } from '../../components';
import { blogCategories, posts, tags } from '../../constants';

import { BsSearch } from 'react-icons/bs';
 
 
  
const BlogSidebar = () => {
  return (  
    <div class="blog-sidebar">
 
        <div class="search sidebar-item">
            <SidebarHeading title='search' />
            <div class="input-box">
                <input type="search" id="search" placeholder="search..." />
                <label for="search"><BsSearch /></label>
            </div>
        </div>


        <div class="category sidebar-item">
            <SidebarHeading title='categories' />
            <div className='box-container'>
            {
                blogCategories.map((category) => {
                    return(
                        <div className='item' key={category.id}>
                            <a href="#">{category.title}</a>
                            <p>({category.quantity})</p>
                        </div>
                    )
                })
            }
            </div>   
        </div>  


        <div class="recent-post sidebar-item">
            <SidebarHeading title='recent posts' />
            <div className='box-container'>
            {
                posts.map((post) => { 
                    return( 
                        <Blog  
                          key={post.id} 
                          image={post.image}
                          heading={post.heading}
                          blog={post} 
                        />)
                })
            } 
            </div>
        </div>


        <div class="tags sidebar-item">
            <SidebarHeading title='tags' />
            <div className='box-container'>
                {
                    tags.map((tag) => {
                        return <div className='tag-item' key={tag.id}>{tag.title}</div>
                    })
                }
            </div>
        </div>
        

    </div>
  )
}

export default BlogSidebar;