import React from 'react';
import './Wishlist.css';

import { PageTitle, WishlistItem } from '../../../components';
import { wishlist } from '../../../constants';



const Wishlist = () => {
  return (  
    <>
      <PageTitle title={'wishlist'} page={'wishlist'} />
      <section className='wishlist'>

        <div className='wishlist-container'>

          <div className='container'> 

            <div className='title'>
              <h3>product</h3>
              <h3>name</h3>
              <h3>price</h3>
              <h3>status</h3>
              <h3>activity</h3>
            </div>

            <div className='box-container'>
              {
                wishlist.map((item) => {
                  return(
                    <WishlistItem
                    id={item.id}
                    image={item.image}
                    name={item.name}
                    price={item.price}
                    status={item.status}
                    />
                  )
                })
              }
            </div>

          </div>
        </div>

      </section>
    </>
  )
}

export default Wishlist;