import { images } from '../constants';

const home = [
    {  
        id: 1,
        image: images.home1,
        title: 'Clean and safe energy, the solar energy',
        content: 'Build a solar panel to save energy costs. With Solar Panels the future is looking bright. Solar energy, the smartest way to power your house.',
    },
    {
        id: 2,
        image: images.home2,
        title: 'Build a solar panel to save energy costs',
        content: 'Build a solar panel to save energy costs. With Solar Panels the future is looking bright. Solar energy, the smartest way to power your house.',   
    },
    { 
        id: 3,
        image: images.home3,
        title: 'Make the future bright with solar power',
        content: 'Build a solar panel to save energy costs. With Solar Panels the future is looking bright. Solar energy, the smartest way to power your house.',     
    },

];

export default home;