import { images } from '../constants';

const team = [ 
    {
        id: 1,
        Image: images.team1,
        name: 'Robin Smith',
        title: 'Manager',
    },
    {
        id: 2,
        Image: images.team2,
        name: 'David Bairstow',
        title: 'Assistent Manager',
    },
    {
        id: 3,
        Image: images.team3,
        name: 'Barry Wood',
        title: 'Director',
    },
    {
        id: 4,
        Image: images.team4,
        name: 'John Snow',
        title: 'technician',
    },
 
];

export default team;