import { images } from '.'; 
 
const projectContent = [
    {
        id: 1,
        image: images.project1,
        title: 'House Solar System',
        category: 'Solar panels',
        tag: 'solar',
        link: '#'
    },
    {
        id: 2,
        image: images.project2,
        title: 'Office Solar Plant',
        category: 'Solar panels',
        tag: 'solar',
        link: '#'
    },
    {
        id: 3,
        image: images.project3,
        title: 'Solar Power Station',
        category: 'Solar panels',
        tag: 'solar',
        link: '#',
    },
    {
        id: 4,
        image: images.project4,
        title: 'Offshore Wind Farm',
        category: 'wind energy',
        tag: 'wind',
        link: '#'
    },
    {
        id: 5,
        image: images.project5,
        title: 'Hill Wind Farm',
        category: 'wind energy',
        tag: 'wind',
        link: '#'
    },
    {
        id: 6,
        image: images.project6,
        title: 'hydro power plant',
        category: 'water turbines',
        tag: 'water',
        link: '#'
    },
    {
        id: 7,
        image: images.project7,
        title: 'river plant',
        category: 'water turbines',
        tag: 'water',
        link: '#'
    },
    {
        id: 8,
        image: images.project8,
        title: 'Hybrid Charging Station',
        category: 'hybrid energy',
        tag: 'hybrid',
        link: '#'
    },
    {
        id: 9,
        image: images.project9,
        title: 'Hybrid Power Plant',
        category: 'hybrid energy',
        tag: 'hybrid',
        link: '#'
    },
];

export default projectContent;

