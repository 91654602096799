import React from 'react';
import './Logo.css';

import { images } from '../../constants';
 
import { Link } from "react-router-dom";



const Logo = () => { 
  return (
    <Link to='/'  className='logo'>
        <img src={images.logo} alt='header-logo' />
        <div className='text'>
          <h3> Sunspot </h3>
          <p>solar energy</p>
        </div>
    </Link> 
  )
}

export default Logo;