import React, {useState, useEffect} from 'react';
import './Projects.css';
 
import { ProjectList, Project, PageTitle } from '../../../components';
import { projectContent } from '../../../constants';

import { LightBox } from 'react-lightbox-pack';
import "react-lightbox-pack/dist/index.css"; 

   
   
const Projects = () => {

  // State
  const [tag, setTag] = useState('all');
  const [filteredImages, setFilteredImages] = useState([]);

	const [toggle, setToggle] = useState(false);
	const [sIndex, setSIndex] = useState(0);

	// Handler
	const  lightBoxHandler  = (state, sIndex) => {
		setToggle(state);
		setSIndex(sIndex);
	};

  const popupImage = (index) => {
    lightBoxHandler(true, index);
  } 

  useEffect( () => {
    tag == 'all' ? setFilteredImages(projectContent) : setFilteredImages(projectContent.filter((image) => image.tag == tag))
  }, [tag])

  const list = [
    {
      id: "all", 
      title: "all",
    },
    {
      id: "solar",
      title: "Solar panels",
    },
    {
      id: "wind",
      title: "wind energy", 
    },
    {
      id: "water",
      title: "water turbines",
    }, 
    {
      id: "hybrid",
      title: "hybrid energy",
    }, 
  ];
 
  
  return (
    <>
    <PageTitle title={'projects'} page={'projects'} />
    <section className='projects' id='projects'>

      <ul className='controls'>
        { 
          list.map((item) => {
            return(
              <ProjectList
              id={item.id} 
              title={item.title}
              setSelected={setTag}
              active={tag == item.id ? true: false }
              />
            ) 
          })
        }
      </ul> 

      <div className='box-container'>
        {
          filteredImages.map((item, index) => {
            return(
              <Project
              id={item.id}
              image={item.image}
              title={item.title}
              category={item.category}
              link={item.link}
              index={index}
              popup={popupImage}
              /> 

            )
          })
        }

        <LightBox
          state={toggle}
          event={lightBoxHandler}
          data={projectContent}
          imageWidth="60vw"
          imageHeight="70vh"
          thumbnailHeight={50}
          thumbnailWidth={50}
          setImageIndex={setSIndex}
          imageIndex={sIndex}
        />
          
      </div>

    </section>
    </>

  )
}

export default Projects;