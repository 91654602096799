import React from 'react';
import './Blog.css';

import { Link } from 'react-router-dom'; 
import { AiTwotoneCalendar } from 'react-icons/ai';

   
 
const Blog = (props) => { 
  const {image, heading, content, blog, label, adminImage, adminName, date} = props;
  return (  
    <div className='blog-item'>

        <div className='image'>
          <img src={image} alt='Blog' />
        </div>

        <div className='content'>
          <div className='details'>
            <div className='label'>{label}</div>
            <div className='date'><AiTwotoneCalendar />{date}</div>
          </div>
          <Link to={{
            pathname: '/Blogs/' + heading,
          }} 
          state= {blog}
          className='main-heading'>{heading}
          </Link>
          <p>{content}</p>    
        </div>

    </div>
  )
}

export default Blog;