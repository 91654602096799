import React from 'react';
import './CartItem.css';

import { Quantity } from '../../components'; 

import { RiDeleteBin5Fill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
  


const CartItem = ({id, image, name, price, quantity,total}) => {

  return (
    <div className='item' key={id}>
      <img className='box' src={image} alt='' /> 
      <div className='box name'>{name}</div>
      <div className='box price'>{price}</div>
      <div className='box quantity'><Quantity quantity={quantity} /></div>
      <div className='box total'>{total}</div>
      <div className='box action'>
        <Link to='/'><RiDeleteBin5Fill className='icon delete' /></Link> 
      </div>
    </div>
  )
}

export default CartItem;