import { images } from '../constants';

export const categories = [  
    {
        id: 1,
        title: 'Solar Energy',
        quantity: '9',
    },
    {
        id: 2,
        title: 'Solar Panels',
        quantity: '2',
    }, 
    {
        id: 3, 
        title: 'Wind Turbines',
        quantity: '3',
    },
    {
        id: 4,
        title: 'Water energy',
        quantity: '10',
    },
    {
        id: 5,
        title: 'Hybrid energy',
        quantity: '15',
    },
];

export const posts = [ 
    {   
        id: 1, 
        image: images.post1,
        label: 'Solar Energy',
        date : 'February 19th, 2022',
        heading: 'Impact of Solar Energy',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.post1gallery1},
            {id: 2, image: images.post1gallery2},
        ],
        tags: [ 
            {id: 1, title: 'solar'},
            {id: 2, title: 'energy'},
            {id: 3, title: 'clean'},
        ],
        comments: [
            {
                id: 1,
                image: images.post1comment1,
                name: 'Thomas Kelly',
                date: 'feb 10th, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.post1comment2,
                        name: 'Wendy Hills',
                        date: 'july 20th, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.post1comment3,
                name: 'Lorraine Hill',
                date: 'sep 27th, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 2, 
        image: images.post2,
        label: 'Green Energy',
        date : 'may 29th, 2022',
        heading: 'What is Green Energy?',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.post2gallery1},
            {id: 2, image: images.post2gallery2},
        ],
        tags: [ 
            {id: 1, title: 'solar'},
            {id: 2, title: 'energy'},
            {id: 3, title: 'clean'},
        ],
        comments: [
            {
                id: 1,
                image: images.post2comment1,
                name: 'Thomas Kelly',
                date: 'feb 10th, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.post2comment2,
                        name: 'Wendy Hills',
                        date: 'july 20th, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.post2comment3,
                name: 'Lorraine Hill',
                date: 'sep 27th, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 3, 
        image: images.post3,
        label: 'Solar Energy',
        date : 'august 3rd, 2022',
        heading: 'Solar Panel Savings',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.post3gallery1},
            {id: 2, image: images.post3gallery2},
        ],
        tags: [ 
            {id: 1, title: 'solar'},
            {id: 2, title: 'energy'},
            {id: 3, title: 'clean'},
        ],
        comments: [
            {
                id: 1,
                image: images.post3comment1,
                name: 'Thomas Kelly',
                date: 'feb 10th, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.post3comment2,
                        name: 'Wendy Hills',
                        date: 'july 20th, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.post3comment3,
                name: 'Lorraine Hill',
                date: 'sep 27th, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
];

export const tags = [
    {
        id: 1,
        title: 'solar',
    },
    {
        id: 2,
        title: 'energy',
    },
    {
        id: 3,
        title: 'panels',
    },
    {
        id: 4,
        title: 'eco', 
    },
    {
        id: 5,
        title: 'hybrid', 
    },
    {
        id: 6,
        title: 'water',
    }, 
];