import React from 'react';
import './OrderItem.css';



const OrderItem = ({id, image, name, price, quantity, total}) => {
  return (
    <div className='item' key={id}>
        <img className='box' src={image} alt='' />
        <div className='box name'>{name}</div>
        <div className='box price'>{price}</div>
        <div className='box quantity'>{quantity}</div>
        <div className='box total'>{total}</div>
    </div>
  ) 
}
  
export default OrderItem