import React from 'react';
import './EditAddress.css';

import { Button, PageTitle } from '../../../components';



const EditAdress = () => {
  return (
    <>
      <PageTitle title={'edit address'} page={'edit address'} />
      <section className='edit-address'>
        <form className="form" action="example.php">
          <h3>edit address</h3>
          <div className="input-box">
              <input type="text" name="first-name" placeholder="first name" id="first-name" className="box" />
              <input type="text" name="last-name" placeholder="last name" id="last-name" className="box" />
          </div> 

          <div className="input-box">
              <input type="text" name="company" placeholder="company (optional)" id="company" className="box" /> 
              <input type="text" name="country" placeholder="country" id="country" className="box" />
          </div>

          <div className="input-box">
              <input type="text" name="address" placeholder="address" id="address" className="box" />
              <input type="text" name="city" placeholder="city" id="city" className="box" />
          </div>

          <div className="input-box">
              <input type="text" name="province" placeholder="province" id="province" className="box" />
              <input type="text" name="zip" placeholder="ZIP" id="zip" className="box" />
          </div>

          <button type="submit" name="submit" id="submit"><Button title={"edit address"} /></button>
        </form>
      </section>
    </>
  )
}

export default EditAdress