import { images } from "../constants";

export const orderSummary = [  
    {
        orderID: '#12345', 
        delivery: 'Mar 25th, 2021',
        items: [
            {id: 1, name: 'sub total', value: '$1830.00'},
            {id: 2, name: 'delivery fee', value: '$200.00'},
            {id: 3, name: 'discount', value: '$100.00'},
            {id: 4, name: 'tax', value: '$100.00'},
            {id: 5, name: 'total', value: '$2030.00'},
        ],
    },
];

export const items = [
    {
        id: 1,
        image: images.orderlist1,
        name: 'solar panel 400w',
        price: '$50.00',
        quantity: '1',
        total: '$50.00',
    },
    {
        id: 2,
        image: images.orderlist2,
        name: 'solar panel 200w',
        price: '$40.00', 
        quantity: '2',
        total: '$80.00',
    },
    {
        id: 3,
        image: images.orderlist3,
        name: 'wind turbine',
        price: '$600.00',
        quantity: '1',
        total: '$600.00',
    },
    {
        id: 4,
        image: images.orderlist4, 
        name: 'Solar street charger',
        price: '$500.00',
        quantity: '1',
        total: '$500.00',
    },
    {
        id: 5,
        image: images.orderlist5,
        name: 'solar light',
        price: '$200.00',
        quantity: '3',
        total: '$600.00',
    },
];
