import React from 'react';
import './PagesNo.css';

import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

 
const PagesNo = () => {
  return ( 
    <div class="pages-no">
        <div class="container"> 
            <div class="item"> 
                <BsArrowLeft />
            </div>
            <div class="numbers">
                <div class="item active">1</div>
                <div class="item">2</div>
                <div class="item">3</div>
            </div>
            <div class="item">
                <BsArrowRight />
            </div>
        </div>

    </div>
  )
}

export default PagesNo