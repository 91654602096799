import React from 'react';
import './Pricing.css';

import { Button, PageTitle } from '../../../components';
import { pricingContent } from '../../../constants';

import { TiTick } from 'react-icons/ti';
 


const Pricing = () => {  
  return (  
    <>
      <PageTitle title={'pricing plan'} page={'pricing'} />
      <section className='pricing' id='pricing'>

      <div className='box-container'> 
        { 
          pricingContent.map((item) => {
            const listItems = item.list;
            return(
              <div class="price-item" key={item.id}>
                <div className='intro'>
                  <h3>{item.title}</h3>
                  <p>{item.content}</p>
                  <div class="price">${item.price}<span>/month</span></div>
                </div>
                <Button title={'buy now'} />
                <ul>
                  {
                    listItems.map((l) => {
                      return <li key={l.id}><TiTick className='icon' /> {l.name}</li>
                    })
                  }
                </ul>
              </div>
            )
          })
        }
      </div>

    </section>
    </>
  )
}

export default Pricing;