import { images } from '.';

const testimonials = [ 
    { 
        id: 1,
        image: images.testimonial1,
        name: 'Matthew Wade',
        title: 'Customer',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi, eligendi sint assumenda eum illo similique repellendus.',
    },
    {
        id: 2,
        image: images.testimonial2,
        name: 'Karen Brown',
        title: 'Customer',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi, eligendi sint assumenda eum illo similique repellendus.',
    },
    { 
        id: 3,
        image: images.testimonial3,
        name: 'James Pattinson',
        title: 'Customer',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi, eligendi sint assumenda eum illo similique repellendus.',
        
    },
];

export default testimonials;