import { images } from "../constants";

const wishlist = [
    {
        id: 1, 
        image: images.wishlist1,
        name: 'solar panel 400w',
        price: '$50.00',
        status: 'InStock',
    },
    {
        id: 2,
        image: images.wishlist2,
        name: 'solar panel 200w',
        price: '$40.00', 
        status: 'OutOfStock',
    },
    {
        id: 3,
        image: images.wishlist3,
        name: 'wind turbine',
        price: '$600.00',
        status: 'OutOfStock',
    },
    {
        id: 4,
        image: images.wishlist4,
        name: 'Solar street charger',
        price: '$500.00',
        status: 'InStock',
    },
    {
        id: 5,
        image: images.wishlist5,
        name: 'solar light',
        price: '$200.00',
        status: 'InStock',
    },
];

export default wishlist;