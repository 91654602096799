import React from 'react'; 
import './About.css';

import { Heading, Button } from '../../../components';
import { images } from '../../../constants';

import { MdDoubleArrow } from 'react-icons/md';

  

const About = () => {   
  return (
    <section className='about' id='about'>

      <Heading title={'about us'} span={'know about us'} />

      <div className='box-container'>

        <div className='image'>
          <img src={images.about} alt='' />
        </div>

        <div class="content">
          <h2>Solar energy, clean energy for a better tomorrow.</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
              Eum voluptatibus dolores aspernatur animi perferendis iste! 
              Culpa aut enim debitis optio illum ipsum quis perferendis.</p>
          <ul className='features'>
            <li><MdDoubleArrow className='icon' />clean energy</li>
            <li><MdDoubleArrow className='icon' />low installation cost</li>
            <li><MdDoubleArrow className='icon' />easy installation</li>
            <li><MdDoubleArrow className='icon' />renewable resources</li> 
          </ul>
          <Button link={'contact-us'} title={'contact us'} />
        </div>
 
      </div> 

    </section>
  )
}

export default About;