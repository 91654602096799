import React from 'react';
import './Counter.css';

import { FaSolarPanel, FaToolbox } from 'react-icons/fa';
import { GoChecklist } from 'react-icons/go';
import { HiUserGroup } from 'react-icons/hi';

import CountUp from 'react-countup';



const Counter = () => {
  return ( 
    <div class="counting">

        <div class="box">
            <GoChecklist className='icon' />
            <CountUp class="count" end={50} duration={3} suffix={'+'} />
            <h3>projects completed</h3>
        </div>
        
        <div class="box">
            <HiUserGroup className='icon' />
            <CountUp class="count" end={300} duration={3} suffix={'+'} />
            <h3>Satisfied Customers</h3>
        </div> 

        <div class="box"> 
            <FaSolarPanel className='icon' />
            <CountUp class="count" end={400} duration={3} suffix={'+'} />
            <h3>solar installations</h3>
        </div>
                    
        <div class="box">
            <FaToolbox className='icon' />
            <CountUp class="count" end={100} duration={3} suffix={'+'} />
            <h3>technician</h3>
        </div>

    </div>
  )
}

export default Counter;