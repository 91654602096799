import { images } from '.';


const services = [ 
    {
        id: 1,
        image: images.service1,
        title: 'solar panel installation',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.service11},
            {id: 2, image: images.service12},
        ],
        features: [ 
            {id: 1, title: 'Solar Technology'},
            {id: 2, title: '24/7 Support& Maintenance'},
            {id: 3, title: 'clean energy'},
            {id: 4, title: 'Easy Installation'},
            {id: 5, title: 'Renewable Resources'},
            {id: 6, title: 'Low Installation Cost'},
        ],
    },
    {
        id: 2,
        image: images.service2,
        title: 'wind energy installation',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.service21},
            {id: 2, image: images.service22},
        ],
        features: [ 
            {id: 1, title: 'Solar Technology'},
            {id: 2, title: '24/7 Support& Maintenance'},
            {id: 3, title: 'clean energy'},
            {id: 4, title: 'Easy Installation'},
            {id: 5, title: 'Renewable Resources'},
            {id: 6, title: 'Low Installation Cost'},
        ],
    },
    {
        id: 3,
        image: images.service3,
        title: 'water turbines',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.service31},
            {id: 2, image: images.service32},
        ],
        features: [ 
            {id: 1, title: 'Solar Technology'},
            {id: 2, title: '24/7 Support& Maintenance'},
            {id: 3, title: 'clean energy'},
            {id: 4, title: 'Easy Installation'},
            {id: 5, title: 'Renewable Resources'},
            {id: 6, title: 'Low Installation Cost'},
        ],
    },
    {
        id: 4,
        image: images.service4,
        title: 'hybrid energy',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.service41},
            {id: 2, image: images.service42},
        ],
        features: [ 
            {id: 1, title: 'Solar Technology'},
            {id: 2, title: '24/7 Support& Maintenance'},
            {id: 3, title: 'clean energy'},
            {id: 4, title: 'Easy Installation'},
            {id: 5, title: 'Renewable Resources'},
            {id: 6, title: 'Low Installation Cost'},
        ],
    },
    {
        id: 5,
        image: images.service5,
        title: 'repair services',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.service51},
            {id: 2, image: images.service52},
        ],
        features: [ 
            {id: 1, title: 'Solar Technology'},
            {id: 2, title: '24/7 Support& Maintenance'},
            {id: 3, title: 'clean energy'},
            {id: 4, title: 'Easy Installation'},
            {id: 5, title: 'Renewable Resources'},
            {id: 6, title: 'Low Installation Cost'},
        ],
    },
    {
        id: 6,
        image: images.service6,
        title: 'Maintenance services',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.service61},
            {id: 2, image: images.service62},
        ],
        features: [ 
            {id: 1, title: 'Solar Technology'},
            {id: 2, title: '24/7 Support& Maintenance'},
            {id: 3, title: 'clean energy'},
            {id: 4, title: 'Easy Installation'},
            {id: 5, title: 'Renewable Resources'},
            {id: 6, title: 'Low Installation Cost'},
        ],
    },

];

export default services;