import { images } from ".";

const profile = {
    image: images.profile,
    name: 'William Cooper',
    gmail: 'abc@gmail.com',
    contactNo: '0123456789',
    address: [ 
        { 
            id: 1,
            title: 'shipping address',
            name: 'William Cooper',
            country: 'pakistan',
            city: 'karachi',
            province: 'sindh',
            ZIP: '12345',
            address: 'xyz Centre A.B.C Road',
        },
        {
            id: 2,
            title: 'billing address',
            name: 'William Cooper',
            country: 'pakistan',
            city: 'karachi',
            province: 'sindh',
            ZIP: '12345',
            address: 'xyz Centre A.B.C Road',
        },
    ],
};

export default profile;